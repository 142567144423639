.reactModal {
  top: 100px !important;
  height: fit-content;
  margin: auto;
  max-width: 1000px;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 40px;
  padding-top: 20px;
  width: fit-content;
  max-height: 88vh;
}

.titleRoot {
  margin-bottom: 14;
  color: gray;
  text-align: center;
}

.modalBody {
  min-height: 250px;
}

.footerModalButtons {
  margin: 20px;
  justify-content: flex-end;
  text-align: center;
}

.modalText {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.buttonsModal {
  min-width: 250px;
  min-height: 50px;
}