.tableHead {
  background-color: #0d071b;
}

.tableHeadName {
  color: white;
  font-weight: 700;
  justify-content: center;
  align-items: center;
}

.paginationContent {
  display: flex;
  justify-content: flex-end;
}

.tableCollapse {
  padding: 5px !important;
}

.divFlutuante {
  background-color: #0d071b;
  padding: 20px 30px 5px 5px;
  position: absolute;
  min-width: 250px;
  margin-top: -90px;
  margin-left: -250px;
  border-radius: 10px;
  z-index: 200;
}

.divClose {
  z-index: 100;
  /* background-color: #000000d9; */
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
}

.divFlutuante li {
  cursor: pointer;
  list-style-type: none;
  margin-bottom: 10px;
}

.menuItem {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  cursor: pointer;
  color: white;
}

.menuItem svg {
  margin-right: '10px';
}

.menuItem.customOption {
  display: flex;
  align-items: center;
}

.organizzer {
  display: flex !important;
  justify-content: flex-end !important;
}

.organizzer button {
  margin-bottom: -20px;
}

.table-empty-div {
  text-align: center;
  padding: 30px 20px;
  font-style: italic;
  font-weight: bold;
  font-size: 1.3rem;
}
