.formSelect {
  padding: 10px;
}

.formSelect input {
  padding: 10px;
  border-radius: 5px;
}

.formSelect2 input {
  padding: 6px;
  border-radius: 5px;
}