.fluid {


    box-shadow: 0px 2px 2px rgb(0 0 0 / 14%), 0px 3px 1px rgb(0 0 0 / 12%), 0px 1px 5px rgb(0 0 0 / 20%);
    margin-bottom: 25px;
    margin-top: 25px;
    background-color: #fff;
    padding: 30px;
}

.text {
    padding: 40px;
    font-size: 20px;
    font-style: italic;
    text-align: center;
}

.marginButton {
    margin: 20px;
}

// antigas 

.titleRoot {
    margin-Bottom: 14;
}

.reactModal {
    top: '100px !important';
    height: 'fit-content';
    margin: 'auto';
    max-Width: '1000px';
    position: 'absolute';
    inset: '40px';
    border: '1px solid rgb(204, 204, 204)';
    background: 'rgb(255, 255, 255)';
    overflow: 'auto';
    border-Radius: '4px';
    outline: 'none';
    padding: '40px';
    padding-Top: '20px';
    width: 'fit-content';
    max-Height: '88vh';

}

.reactModalOverlay {
    position: 'fixed';
    inset: '0px';
    background-Color: 'rgba(0, 0, 0, 0.7)';
    overflow: 'scroll';
    z-Index: 1;
}

.formSelect {
    width: '100%';
    margin-left: auto;
    margin-right: auto;
}

.mtFormSelect {
    margin-Top: '16px';
}

.footerModalButtons {
    margin: 30px;

}

.mtp3rem {
    margin-Top: '3rem';
    margin-Bottom: '3rem';
}

.mAuto {
    margin: 'auto';
}

.dFlexAlign {
    display: 'flex';
    flex-Direction: 'column';
    justify-Content: 'center';
    align-Items: 'center';
}

.dFlexAlignCenter {
    display: 'flex';
    align-Items: 'center';
}

.closeButtom {
    display: 'flex';
    align-Items: 'flex-start';
    justify-Content: 'flex-end';
    height: 'fit-content';
    width: 'fit-content';
    background: 'rgba(0,0,0,0.1)';
    border-Radius: '50%';
    padding: '5px';
    position: 'absolute';
    right: '1rem';
    top: '1rem';
    cursor: 'pointer';
    ;
}

.dFlex {
    display: 'flex';
}

.pb3 {}

.containerTicketsData {
    background: '#fafbff';
    padding: '30px';
    height: '100%';
}

.containerInputTickets {
    input {
        border-Radius: 0;
        box-Shadow: 'unset !important';
        height: '50px';
        padding-Left: '15px';
        font-Weight: 500;
        border: '2px solid #eee';
        background-Color: '#fff';
        width: '100%';

        .inputError {
            border: '1px solid #d93025';
        }
    }

    label {
        .textError {
            color: '#d93025';
        }
    }
}


.containerFeedback {
    margin-Top: '50px';
}

.containerTitleFeedback h2 {
    font-Size: '24px';
    font-Weight: 700;
    color: '#001d4a';

}

.checkoutButton {
    padding: '12px 30px';
}

.containerMsgFeedback {}

.containerCopyButton {
    margin-Left: 0;
    padding-Left: 0;
}

.minHeight45 {
    min-Height: '45px';
}

.dataEmpty {
    text-Align: 'center';
    padding: '40px';
    font-Size: '20px';
    font-Style: 'italic';
}

.alignItems {
    align-Items: 'center';
}

.justifyContent {
    justify-Content: 'center' !important;


    margin-bottom: 20px;

}

.margin {
    margin-left: auto;
    margin-right: auto;
}

.mt10 {
    margin-Top: '10px';
}

.tamanho {
    width: 60%;
}


.alertInstancia {
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 5px;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.alertInstancia2 {
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 5px;
    background-color: #f8f0d7;
    border-color: #f8f0d7;
    color: #72681c;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}