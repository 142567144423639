
.customDropzone{
    flex: 1;
    border: 2px dashed rgba(0, 0, 0, 0.06);
    display: flex;
    outline: none;
    padding: 40px 20px;
    transition: border .24s ease-in-out;
    align-items: center;
    border-radius: 2px;
    flex-direction: column;
    background-color: #f4f4f7;
    min-height: fit-content;
}