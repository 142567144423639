.collapse-user-instance {
  .tableContainer {
    width: 100%;
    padding-bottom: 10px;
  }

  .warning {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
