.customModalOverlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

.select-error {
  border: solid 1px #f44336;
  border-radius: 4px;
}
