.logoPrincipal {
  height: 60px !important
}

.new-tag {
  background-color: #F44336;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 3px;
  border-radius: 4px;
  margin-left: 8px;
  position: absolute;
}

span {
  font-size: 16px;
  font-weight: 600;
}

.sub-menu span {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-left: 15px;
}

.waves-effect {
  padding: 10px !important;
}

body.vertical-collpsed.sidebar-enable .navbar-brand-box {
  padding: 10px;
}

body.vertical-collpsed.sidebar-enable .navbar-brand-box img {
  max-width: 100%;
}