.mini-stat .mini-stat-img {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.indicadores {
  display: flex;
  margin: 10px;
  align-items: center;
}

.indicadores span {
  margin-left: 10px;
}

.newBg {
  background-color: #0D071B !important;
  color: #fff;
  margin-bottom: -40px;
  margin-right: 25px;
  cursor: 'pointer';
}


.legenda-bot li {
  font-size: 0.8rem;
  color: #808080;
}

.legenda-bot {
  margin-left: 20px;
}