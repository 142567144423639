.automation-campaign-page {
  .mini-stat .mini-stat-img {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }

  .indicadores {
    display: flex;
    margin: 10px;
    align-items: center;
  }

  .indicadores span {
    margin-left: 10px;
  }
}
