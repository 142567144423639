.modal-zap-mais-overlay {
  inset: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-zap-mais {
  background-color: #fff;
  padding: 20px;
  max-width: 50%;
  max-height: 70%;
  overflow-y: auto;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  @media(max-width: 768px){
    max-width: 100%;
    max-height: 100%;
  }
}

.modal-zap-mais-footer{
  display: flex;
  justify-content: flex-end;
}