.container-pagination {
  display: flex;
  justify-content: space-between;

  select {
    width: fit-content;
    height: 38px;
    margin-left: 10px;
  }
}
