@import '../../styles/colors';

.zap-mais-button {
  background-color: $button-color !important;
  color: $button-text-color !important;
  font-weight: 500 !important;
  border: none !important;
}

.apply-padding {
  padding: 15px 30px 15px 30px !important;
}

.h-fit {
  height: fit-content !important;
}
