.container-criar-inscricao {
  margin-top: 50px;
  .instancia-card {
    display: block;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #0d071b;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(1, 21, 81, 0.1);
    margin-top: 40px;
    overflow: hidden;
    min-height: 250px;
  }

  .plan-card {
    text-align: center;
    align-items: center;
    background-color: #0d071b;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(1, 21, 81, 0.1);
    max-width: 310px;
    margin-top: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &.selected {
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }
    &.favorite {
      margin-top: 0;
    }
  }

  .plans-container {
    gap: 20px;
    justify-content: center;
  }

  .plan-title {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: rgb(189, 173, 197);
  }

  .instancia-title {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
    color: rgb(189, 173, 197);
  }

  .instancia-features {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .instancia-features li {
    margin-bottom: 5px;
    font-size: 12px;
    color: aliceblue;
  }

  .plan-features {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
    min-height: 150px;
  }

  .plan-features li {
    margin-bottom: 10px;
    font-size: 12px;
    color: aliceblue;
  }

  .instancia-price {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    color: white;
  }

  .plan-price {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
  }

  .plan-button {
    color: #0d071b;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .plan-button:hover {
    color: #fff;
    background-color: #b8b8b9;
    cursor: pointer;
  }

  .plan-tag {
    position: relative;
    top: -10px;
    left: -130px;
    width: 100px;
    height: 25px;
    background-color: red;
    transform: rotate(-45deg);
    text-align: center;
  }

  .plan-tag span {
    font-size: 12px;
    color: white;
    transform: translate(-50%, -50%);
  }

  .instancia-tag {
    position: relative;
    top: -5px;
    left: -45px;
    width: 100px;
    height: 25px;
    background-color: rgb(255, 255, 255);
    transform: rotate(-45deg);
    text-align: center;
  }

  .instancia-tag span {
    font-size: 12px;
    color: white;
    transform: translate(-50%, -50%);
  }

  .container-instance-number {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .container-header-text {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    .input-group-text {
      cursor: pointer;
    }
  }
  .container-footer {
    display: block;
    .button-subscribe {
      width: 115px;
    }
    input {
      max-width: 100%;
      width: 220px;
    }
  }
  .input-group-instance-qtd {
    width: fit-content;
    .input-instance-qtd {
      width: 50px;
      text-align: center;
    }
  }

  .container-checkout {
    .button-next {
      margin-left: auto;
      display: flex;
      width: 200px;
      text-align: center;
      justify-content: center;
    }
    .button-copy {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      gap: 10px;
    }
    .button-download {
      display: flex;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    .container-step-buttons {
      display: flex;
      justify-content: space-between;
      .button-previous {
        display: flex;
      }
    }
    .container-checkout-result {
    }

    .feedback-header-message {
      margin-top: 30px;
      text-align: center;
    }

    .image-qrcode {
      max-width: 250px;
      margin-left: auto;
      display: flex;
      margin-right: auto;
      margin-top: 20px;
    }
  }

  .select-another-plan {
    width: fit-content;
    cursor: pointer;
    margin-top: 20px;
  }

  .card-subscription {
    border-bottom: solid 1px #dee2e6;
  }

  .card-subscription-chevron {
    margin-right: 20px;
  }

  .container-buttons {
    gap: 20px;
    justify-content: flex-end;
    button {
      width: fit-content;
    }
  }
}
