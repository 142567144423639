.newBg {
  background-color: #0D071B !important;
  color: #fff !important;
}

.background-login {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.font-size-20 {
  color: #0D071B !important;
}

.login-page {
  overflow-y: hidden;
  overflow-x: hidden;
}