
.containerButtons{
    margin: 10px 0;
    border: 1px solid #ccc !important;
    background-color: #f0f2f5;
    padding: 6px 5px 0;

    button{
        padding: 5px;
        background-color: rgb(51, 53, 71);
        color: white;
        border-radius: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        border: none;

        &:hover{
            background-color: rgb(81, 83, 111);
        }
    }
}