.draggableArea {
    position: absolute;
    cursor: move;
    color: black;
    // max-width: 215px;
    border-radius: 5px;
    // padding: 1em;
    margin: auto;
    user-select: none;
}

.onlyDropzone {
    margin-inline: 20px;
}

.checkSair {
    display: flex;
    margin-top: 40px !important;
    justify-content: space-between;
}



.dropzoneAtFooter {
    width: 540px;
    margin-top: 15px;
}

.sliderWidth {
    width: 540px !important;
    margin-top: 50px;
}

.textEditorWrapper {
    margin-inline: 20px;
    background-color: #f0f2f5;
    min-height: 15rem;
    padding-bottom: 5px;
}

.textEditorContent {
    padding: 9px 12px 11px;
    margin: 10px 10px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    max-height: 90%;
}

.textEditorToolbar {
    background-color: #f0f2f5;
    border-bottom: solid 2px #fff;
}

.gridContainer {
    background-color: #f4f4f7;
    padding: 15px 20px;
    border-top: solid 1px #fff;
    padding-bottom: 25px;
}

.inputEventozz {
    input {
        background-color: #fff;
    }
}

.extraPadding {
    padding: 8px;
}

.backgroundWhite {
    background-color: #fff;
}

.errorValidation {
    border: dashed 1px #f44336 !important;
    color: #f44336;
}

.helperText {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: Normal;
    font-weight: 400;
    line-height: 1.66;
    margin-inline: 20px;
}