.container-financial {
  .mini-stat .mini-stat-img {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }

  .indicadores {
    display: flex;
    margin: 10px;
    align-items: center;
  }

  .indicadores span {
    margin-left: 10px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }

  .h-fit {
    height: fit-content;
  }

  .bg-dark {
    background-color: #0d071b;
    border: none;
  }
}
