.indicadores {
  display: flex;
  margin: 10px;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

.miniStat {
  .miniStatImg {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;

    img {
      max-width: 32px;
    }
  }
}

.paddingBottom250 {
  padding-bottom: 250px !important;
}

.containerQrcode {
  text-align: center;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 270px;
  min-height: 270px;
  margin-top: 50px;
  margin-bottom: 50px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: 270px;
    height: 270px;
  }
}

.loadingQrcodeContainer {
  position: absolute;
  top: 0;
  width: 270px;
  height: 270px;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  background-color: rgba(0, 0, 0, .4);
  color: #fff;

  .loadingText {
    width: 100%;
  }

}

.textHeaderContainer {
  text-align: center;
}

.constainerIsAuthenticated {
  color: rgb(0, 172, 90) !important;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(229, 231, 235);
  width: 100px;
  border-radius: 50%;
  margin-top: 25px;
}

.qrCodeBackground {
  position: absolute;
  z-index: -1;
  opacity: 0.1;
}

.containerRefreshQrcode {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img.iconRefresh {
    width: 150px;
    height: 150px;
  }
}

.containerImgRefreshQrcode {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.queueCount{
  background-color: rgba(51, 53, 71, 0.87);
  padding: 5px 20px;
  border-radius: .5rem;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
}