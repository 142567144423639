.reactModal {
  top: 100px !important;
  height: fit-content;
  margin: auto;
  max-width: 1000px;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 40px;
  padding-top: 20px;
  width: 100%;
  max-height: 88vh;

  @media (max-width: 768px) {
    inset: 0;
  }
}

.titleRoot {
  margin-bottom: 14;
  color: gray;
  text-align: center;
}

.modalBody {
  min-height: 250px;
}

.footerModalButtons {
  margin: 20px;
  justify-content: center;
  text-align: center;
}

.modalText {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.buttonsModal {
  min-width: 250px;
  min-height: 50px;
}

.container-buttons {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  button {
    width: fit-content;
  }
}
